import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

const IS_DEVELOPMENT = isDevelopmentENV();

export const someoneHealthEnvironment = () => ({
  SomeoneHealthURL: 'https://someone.health/',
  ScheduleAppointmentURL: 'https://someone.health/schedule/',
  PsychologistsURL: 'https://someone.health/psychologists/',
  PricingURL: IS_DEVELOPMENT ? 'https://release.someone.health/pricing/' : 'https://someone.health/pricing/',
  FAQURL: 'https://support.someone.health/?_ga=2.143979125.1798702250.1675649702-698124791.1675649702',
  ContactURL: 'https://support.someone.health/',
  AnxietyCounsellingURL: 'https://someone.health/anxiety-counselling/',
  DepressionCounsellingURL: 'https://someone.health/depression-mood-counselling/',
  RelationshipCounsellingURL: 'https://someone.health/relationship-counselling/',
  StressCounsellingURL: 'https://someone.health/stress-management-relaxation-counselling/',
  TraumaCounsellingURL: 'https://someone.health/trauma-ptsd-counselling/',
  GPURL: 'https://someone.health/gp',
  MHTPSurveyURL: IS_DEVELOPMENT
    ? 'https://dev-s1h-gp-survey-real.paperform.co'
    : 'https://forms.tacklit.com/someone-health-gp-new',
  DASS21SurveyURL: IS_DEVELOPMENT ? 'https://dev-s1h-dass-21.paperform.co' : '',
  VTPHNAEmail: 'vtphna@someone.health',
  someoneHealthEmail: 'team@someone.health',
  SomeoneHealthFindPsychologistURL: IS_DEVELOPMENT
    ? 'https://preview.someone.health/find-a-psychologist'
    : 'https://someone.health/find-a-psychologist',
  CancellationPolicy: IS_DEVELOPMENT
    ? 'https://release.someone.health/cancellation-policy'
    : 'https://someone.health/cancellation-policy',
  GPReviewAppointmentTypeId: IS_DEVELOPMENT ? '64813b9003fa5f000b800265' : '6406b01a5c4d77000be31eca'
});

export const DefaultClientTimeZone = 'Australia/Melbourne';
